import React from 'react'
import { Action, Claim } from '@straetus/constants/auth'
import { CompanyEnv, CompanyType, UserType } from '@straetus/react/interfaces'
import { useAbility } from '@straetus/react/modules/auth'

import type { ActiveCompany, ActiveCompanyFeatures, Me, UserFeatures } from '@straetus/react/interfaces'

export const UserContext = React.createContext({})
export const ActiveCompanyContext = React.createContext<ActiveCompany | null | undefined>(null)

export function useMe() {
  return React.useContext(UserContext) as Me
}

export function useActiveCompany(): ActiveCompany {
  return React.useContext(ActiveCompanyContext) as ActiveCompany
}

export function useUserFeatures(): UserFeatures {
  const { features } = useMe()

  return React.useMemo(() => {
    return features || {}
  }, [features]) as UserFeatures
}

export function useCompanyFeatures(): ActiveCompanyFeatures
export function useCompanyFeatures(feature: keyof ActiveCompanyFeatures): boolean
export function useCompanyFeatures(feature?: keyof ActiveCompanyFeatures): ActiveCompanyFeatures | boolean {
  const { features } = useActiveCompany()

  const companyFeatures = features || {} as ActiveCompanyFeatures

  return feature ? Boolean(companyFeatures[feature]) : companyFeatures
}

export function useIsTestCompany(): boolean {
  const activeCompany = useActiveCompany()

  return React.useMemo(() => {
    return activeCompany?.env === CompanyEnv.Test
  }, [activeCompany])
}

export function useIsInternationalLoggedIn() {
  const activeCompany = useActiveCompany()

  return activeCompany
    && activeCompany.type === CompanyType.International
}

export function useIsMasterLoggedIn() {
  const activeCompany = useActiveCompany()

  return activeCompany
    && [CompanyType.International, CompanyType.Master].includes(activeCompany.type)
}

export function useIsFranchiseeLoggedIn() {
  const activeCompany = useActiveCompany()

  return activeCompany
    && [CompanyType.International, CompanyType.Master, CompanyType.Franchisee].includes(activeCompany.type)
}

export function useIsClientLoggedIn() {
  const activeCompany = useActiveCompany()
  const { type } = useMe()

  return React.useMemo(() => (
    activeCompany?.type === CompanyType.Client || type === UserType.Client
  ), [activeCompany?.type, type])
}

export const BETA_FEATURES_STORAGE_KEY = '___straetus-beta'
export const ALPHA_FEATURES_STORAGE_KEY = '___straetus-alpha'

export function useIsInAlpha() {
  const ability = useAbility()

  return React.useMemo(() => (
    ability.can(Action.Manage, Claim.AlphaFeatures) && Boolean(localStorage.getItem(ALPHA_FEATURES_STORAGE_KEY))
  ), [ability])
}

export function useIsInBeta() {
  const ability = useAbility()
  const isInAlpha = useIsInAlpha()

  return React.useMemo(() => (
    isInAlpha || (ability.can(Action.Manage, Claim.BetaFeatures) && Boolean(localStorage.getItem(BETA_FEATURES_STORAGE_KEY)))
  ), [ability, isInAlpha])
}
