import React from 'react'

import type { Breakpoint } from '@mui/system/createTheme'

import { DialogContext } from './dialog.context'

export type OnClick = () => void

export interface Action {
  label: string
  variant?: 'text' | 'outlined' | 'contained'
  onClick: OnClick
}

export interface DialogNodeProps {
  onClose?: () => void
}

export interface DialogOptions {
  actions?: Action[],
  withCancel?: boolean
  withOk?: boolean
  maxWidth?: Breakpoint
  testId?: string
}

export type DialogNode = React.ReactElement

export type DialogContext = (title: string, content: string | DialogNode, options?: DialogOptions) => void

export function useDialog() {
  return React.useContext(DialogContext) as DialogContext
}
