import React from 'react'
import { Action, Claim } from '@straetus/constants/auth'
import { buildAbility, createCaslAbilityBuilder } from '@straetus/node/auth'
import FullScreenLoader from '@straetus/react/components/full-screen-loader'
import { CompanyType, DossierStatus } from '@straetus/react/interfaces'
import { AbilityContext } from '@straetus/react/modules/auth'
import { ActiveCompanyContext,  UserContext } from '@straetus/react/modules/user'

import type { AbilityBuilder, PureAbility } from '@casl/ability'
import type { Dossier, Invoice, Note, Workflow } from '@straetus/react/interfaces'

import { useMeQueryWithAutoCompanySwitch } from './user.hooks'

export default function UserProvider({ children }: React.PropsWithChildren) {
  const loggedInUser = useMeQueryWithAutoCompanySwitch()

  const userAbilities = React.useMemo(() => {
    const abilityBuilder = createCaslAbilityBuilder(
      loggedInUser?.me?.id,
      loggedInUser?.me?.type,
      loggedInUser?.activeCompany?.type,
      loggedInUser?.me?.scopes || []
    ) as AbilityBuilder<PureAbility<[Action, Dossier | Note | Workflow | Claim]>>

    if (loggedInUser?.activeCompany?.type !== CompanyType.International) {
      // Make sure uses can only update their own workflows
      abilityBuilder.cannot<Workflow>(Action.Update, Claim.Workflow, { companyId: { $ne: loggedInUser?.activeCompany?.id } })
    }

    // Make sure the user can only update open / draft dossiers
    abilityBuilder.cannot<Dossier>(Action.Update, Claim.Dossier, {
      status: { $in: [DossierStatus.Paid, DossierStatus.Paused, DossierStatus.AutoResume, DossierStatus.Closed] }
    })

    // Make sure the user can only delete draft dossiers
    abilityBuilder.cannot<Dossier>(Action.Delete, Claim.Dossier, {
      status: { $nin: [DossierStatus.Draft, DossierStatus.AutoOpen] }
    })

    // Make sure the user can only update manual debtor management dossiers
    abilityBuilder.cannot<Dossier>(Action.Update, Claim.Dossier, {
      manager: { $ne: null }
    })

    if (loggedInUser?.activeCompany?.type !== CompanyType.International) {
      // Make sure the user can only update their own invoices
      abilityBuilder.cannot<Invoice>(Action.Update, Claim.Invoice, {
        debtorId: { $eq: loggedInUser?.activeCompany?.id }
      })
    }

    return buildAbility(abilityBuilder as never)
  }, [loggedInUser?.me, loggedInUser?.activeCompany])

  if (!loggedInUser || !loggedInUser.activeCompany) {
    // Only show the delay if the active company/me is still being fetched
    return <FullScreenLoader withDelay={!loggedInUser || !loggedInUser.activeCompany} />
  }

  return (
    <UserContext.Provider value={loggedInUser.me}>
      <ActiveCompanyContext.Provider value={loggedInUser.activeCompany}>
        <AbilityContext.Provider value={userAbilities}>
          {children}
        </AbilityContext.Provider>
      </ActiveCompanyContext.Provider>
    </UserContext.Provider>
  )
}
