import React from 'react'
import { useIntl } from 'react-intl'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import Alert, { AlertProps } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Snackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles'
import { getGlobalGraphQLErrorCode } from '@straetus/react/modules/graphql'

import type { GraphQLError } from 'graphql'

import { DeleteSnackbarContext, SnackbarContext } from './snackbar.context'
import { ErrorMessages, Options, SnackbarDuration } from './snackbar.hooks'

export interface State extends Options {
  open: boolean
  message: string
}

export const StyledAlert = styled(Alert)<AlertProps>(() => ({
  minWidth: 350,
  maxWidth: '60vw'
}))

export default function SnackbarProvider({ children }: React.PropsWithChildren) {
  const intl = useIntl()

  const [state, setState] = React.useState<State>({
    open: false,
    message: ''
  })

  const handleShowSnackbar = React.useCallback((message: string, options: Options = {}) => {
    // Don't show the item if the user already seen / closed this id
    if (options.id && localStorage.getItem(options.id)) {
      return
    }

    setState({
      open: true,
      message,
      ...options,
      duration: options.duration || SnackbarDuration.Normal
    })
  }, [])

  const handleShowDeleteSnackbar = React.useCallback((type: string) => {
    setState({
      open: true,
      message: intl.formatMessage({
        id: 'snackbar.delete.deleting',
        defaultMessage: 'Deleting {type}...'
      }, { type }),
      duration: SnackbarDuration.Indefinitely
    })

    return {
      complete: () => {
        setState({
          open: true,
          message: intl.formatMessage({
            id: 'snackbar.delete.deleted',
            defaultMessage: '{type} deleted!'
          }, {
            // Upper case the first letter
            type: type.charAt(0).toUpperCase() + type.slice(1)
          }),
          duration: SnackbarDuration.Normal
        })
      },
      error: (errors?: readonly GraphQLError[], messages?: ErrorMessages) => {
        // Default message
        let message = intl.formatMessage({
          id: 'snackbar.delete.error',
          defaultMessage: 'An error happened, was unable to delete the {type}!'
        }, { type })

        if (messages && errors) {
          const errorCode = getGlobalGraphQLErrorCode(errors)

          if (errorCode && errorCode in messages) {
            message = intl.formatMessage(messages[errorCode])
          }
        }

        setState({
          open: true,
          message,
          duration: SnackbarDuration.Normal
        })
      }
    }
  }, [])

  const handleClose = React.useCallback((event?: any) => {
    if (state.duration === SnackbarDuration.Indefinitely && event?._reactName !== 'onClick') {
      return
    }

    if (state.id) {
      localStorage.setItem(state.id, 'completed')
    }

    setState((prevState) => ({
      ...prevState,
      open: false
    }))
  }, [state])

  const handleAnimationEnd = React.useCallback(() => {
    setState({
      open: false,
      message: '',
      title: undefined,
      action: undefined,
      severity: undefined,
      duration: SnackbarDuration.Normal
    })
  }, [])

  const action = React.useMemo(() => {
    if (state.action) {
      return state.action(handleClose)
    }

    return null
  }, [state.action, handleClose])

  return (
    <SnackbarContext.Provider value={handleShowSnackbar}>
      <DeleteSnackbarContext.Provider value={handleShowDeleteSnackbar}>
        {children}

        <Snackbar
          action={action}
          message={state.message}
          onAnimationEnd={handleAnimationEnd}
          onClose={handleClose}
          open={state.open}
          autoHideDuration={
            state.duration === SnackbarDuration.Indefinitely
              ? null
              : state.duration
          }
          transitionDuration={{
            enter: 225,
            exit: 195
          }}>
          {state.severity && (
            <StyledAlert
              action={action}
              onClose={handleClose}
              severity={state.severity}
              iconMapping={{
                error: <ErrorRoundedIcon />,
                warning: <WarningRoundedIcon />,
                info: <InfoRoundedIcon />
              }}>
              {state.message && state.title && (
                <AlertTitle>{state.title}</AlertTitle>
              )}

              {state.message || state.title}
            </StyledAlert>
          )}
        </Snackbar>
      </DeleteSnackbarContext.Provider>
    </SnackbarContext.Provider>
  )
}
