import React from 'react'
import { styled } from '@mui/material/styles'
import Alert, { AlertProps, AlertColor } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'

export interface SnackbarAlertProps extends Pick<AlertProps, 'action'> {
  handleClose?: (event: React.SyntheticEvent) => void
  title?: string

  severity: AlertColor
  message: string
}

export const StyledAlert = styled(Alert)<AlertProps>(() => ({
  width: '100%',
  minWidth: 350,
  maxWidth: '60vw'
}))

export const SnackbarAlert: React.FC<React.PropsWithChildren<SnackbarAlertProps>> = ({
  handleClose,
  action,
  severity,
  title,
  message,
  children
}) => (
  <StyledAlert
    onClose={handleClose}
    variant={'standard'}
    iconMapping={{
      error: <ErrorRoundedIcon fontSize={'inherit'} />,
      warning: <WarningRoundedIcon fontSize={'inherit'} />,
      info: <InfoRoundedIcon fontSize={'inherit'} />
    }}
    action={action}
    severity={severity}>
    {message && title && (
      <AlertTitle>
        {title}
      </AlertTitle>
    )}

    {message || title}

    {children}
  </StyledAlert>
)

export default SnackbarAlert
