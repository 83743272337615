import React from 'react'

import { State } from './environment.hooks'

export interface Environment {

  production: boolean,

  errorReportingApiKey?: string,

  statusApi: string
  statusDashboard: string

  docsUrl: string
  debtorPortalUrl: string

  overheidIoApiKey?: string,

  graphql: {
    api: string
    apiWs?: string,
    voipApi?: string
  }

}

export interface UseEnvironment extends Environment {
  system: State
}

export const EnvironmentContext = React.createContext({})

export const useEnvironment = () => React.useContext(EnvironmentContext) as UseEnvironment
