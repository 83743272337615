import React from 'react'

export interface ThemeSwitcher {
  goDark: () => void
  goLight: () => void
  goLtr: () => void
  goRtl: () => void
  isRtl: boolean
  isDark: boolean
}

export const ThemeContext = React.createContext(null)

export const useThemeSwitcher = () => React.useContext(ThemeContext) as ThemeSwitcher


