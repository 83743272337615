import React from 'react'
import { IntlProvider } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Language } from '@straetus/react/interfaces'
import { Theme, useThemeSwitcher } from '@straetus/react/theme'

import { LanguageContext } from './language.hooks'
import { LoadMessages, Locale } from './language.interfaces'
import { getInitialLocale, getMessages, storeNewLocale } from './language.utils'

export interface LanguageProviderProps {
  supportedLocales: Locale[]
  loadMessages: LoadMessages
}

export default function LanguageProvider({
  supportedLocales,
  loadMessages,
  children
}: React.PropsWithChildren<LanguageProviderProps>) {
  const [locale, setLocale] = React.useState(getInitialLocale())
  const { messages, dateMessages } = getMessages(loadMessages, locale as Language)
  const { goRtl, goLtr } = useThemeSwitcher()
  const theme = useTheme<Theme>()

  React.useEffect(() => {
    const htmlAttribute = document.getElementsByTagName('html')?.[0]

    htmlAttribute?.setAttribute('dir', theme.direction)
    htmlAttribute?.setAttribute('lang', locale)
  }, [theme.direction, locale])

  const handleSwitchLanguage = React.useCallback(async (languageCode: Language) => {
    // Prevents the full-screen loader from becoming visible
    await loadMessages(languageCode)

    // If we are changing to Hebrew then also change the direction
    if (languageCode.toLowerCase() === 'he') {
      goRtl()

    } else {
      goLtr()
    }

    storeNewLocale(languageCode)
    setLocale(languageCode)
  }, [goLtr, goRtl])

  const apiValue = React.useMemo(() => ({
    supportedLocales,
    switchLanguage: handleSwitchLanguage,
    // Make sure the first letter is an uppercase so that it matches the Language enum
    activeLocale: locale.replace(/./, (locale) => locale.toUpperCase())
  }), [handleSwitchLanguage, supportedLocales, locale])

  return (
    <LanguageContext.Provider value={apiValue}>
      <LocalizationProvider
        adapterLocale={dateMessages}
        dateAdapter={AdapterDateFns}>
        <IntlProvider
          defaultLocale={'en'}
          locale={locale}
          messages={messages}>
          {children}
        </IntlProvider>
      </LocalizationProvider>
    </LanguageContext.Provider>
  )
}
