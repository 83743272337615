import React from 'react'
import { Switch } from 'react-router-dom'
import { CompatRoute, Navigate } from 'react-router-dom-v5-compat'

import UserProvider from '../modules/user'
import ActivateRoute from './activate'
import AppRoute from './app'
import LoginRoute from './login'
import SelectRoute from './select'

export default function AppRoutes() {
  return (
    <Switch>
      <CompatRoute
        component={LoginRoute}
        path={'/login'} />

      <CompatRoute
        path={'/reset-password'}
        component={({ location }) => (
          <Navigate
            to={{
              ...location,
              pathname: '/login/reset'
            }}
          />
        )} />

      <CompatRoute
        component={ActivateRoute}
        path={'/activate'} />

      <CompatRoute
        component={SelectRoute}
        path={'/select'} />

      <CompatRoute path={'/:companySlug'}>
        <UserProvider>
          <AppRoute />
        </UserProvider>
      </CompatRoute>

      <CompatRoute
        component={() => <Navigate to={'/login'} />}
        path={'/'} />
    </Switch>
  )
}
