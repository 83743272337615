import posthog, { CaptureOptions, CaptureResult, Properties } from 'posthog-js'

export const analyticsClient = new (class AnalyticsClient {

  private $set?: Properties

  private $set_once?: Properties

  public setPersonProperties($set?: Properties, $setOnce?: Properties): void {
    posthog.setPersonProperties(
      this.mergeProperties($set, this.$set),
      this.mergeProperties($setOnce, this.$set_once)
    )

    this.$set = undefined
    this.$set_once = undefined
  }

  /**
   * Will set the person properties with the next capture
   */
  public lazySetPersonProperties($set?: Properties, $setOnce?: Properties) {
    this.$set = this.mergeProperties(this.$set, $set)
    this.$set_once = this.mergeProperties(this.$set_once, $setOnce)
  }

  public capture(eventName: string, properties?: Properties | null, options?: CaptureOptions): CaptureResult | void {
    if (properties) {
      if (properties['$set'] || properties['$set_once']) {
        this.lazySetPersonProperties(properties['$set'], properties['$set_once'])
      }
    }

    const captureResult = posthog?.capture(
      eventName,
      {
        ...properties,

        $set: this.$set,
        $set_once: this.$set_once
      },
      options
    )

    this.$set = undefined
    this.$set_once = undefined

    return captureResult
  }

  private mergeProperties(to?: Properties, add?: Properties) {
    if (to && add) {
      return { ...to, ...add }
    }

    return add
  }

})
