import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import Dialog, { dialogClasses } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'

import type { Breakpoint } from '@mui/system'

import { DialogContext } from './dialog.context'
import { Action, DialogNode, DialogOptions, OnClick } from './dialog.hooks'

export const StyledDialog = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%'
  }
})

export interface State {
  animating: boolean
  open: boolean
  withCancel: boolean
  withOk: boolean
  title: string
  content: string
  maxWidth?: Breakpoint
  Node?: DialogNode
  actions: Action[]
  testId?: string
}

// TODO:: Move to components and create two variants!
//  Expanded one, that accepts Node prop
//  Alert one, that accepts text and actions
export const DialogProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, setState] = React.useState<State>({
    animating: false,
    open: false,
    title: '',
    content: '',
    maxWidth: undefined,
    Node: undefined,
    withCancel: false,
    withOk: false,
    actions: [],
    testId: undefined
  })

  const handleShowDialog = React.useCallback((title: string, content: string | DialogNode, options?: DialogOptions) => {
    setState({
      open: true,
      animating: true,
      withCancel: options?.withCancel ?? true,
      withOk: options?.withOk ?? false,
      title,
      content: typeof content === 'string' ? content : '',
      Node: typeof content !== 'string' ? content : undefined,
      actions: options?.actions ?? [],
      maxWidth: options?.maxWidth,
      testId: options?.testId
    })
  }, [])

  const handleClose = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      open: false
    }))
  }, [])

  const wrapOnClick = (onClick: OnClick) => () => {
    handleClose()
    onClick()
  }

  return (
    <DialogContext.Provider value={handleShowDialog}>
      {children}

      <StyledDialog
        aria-describedby={`${state.title}-description`}
        aria-labelledby={state.title}
        data-test-id={state.testId ? `dialog.${state.testId}` : undefined}
        maxWidth={state.maxWidth}
        onClose={handleClose}
        open={state.open}>
        <DialogTitle id={state.title}>
          {state.title}
        </DialogTitle>

        {state.Node && React.cloneElement(state.Node, {
          onClose: handleClose
        })}

        {!state.Node && (
          <>
            <DialogContent>
              <DialogContentText id={`${state.title}-description`}>
                {state.content}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              {state.withCancel && (
                <Button onClick={handleClose}>
                  <FormattedMessage
                    defaultMessage={'Cancel'}
                    id={'btn.cancel'}
                  />
                </Button>
              )}

              {state.withOk && (
                <Button onClick={handleClose}>
                  <FormattedMessage
                    defaultMessage={'Ok'}
                    id={'btn.ok'}
                  />
                </Button>
              )}

              {state.actions.map((action, index) => (
                <Button
                  key={action.label}
                  autoFocus={(index + 1) === state.actions.length}
                  onClick={wrapOnClick(action.onClick)}
                  variant={action.variant}>
                  {action.label}
                </Button>
              ))}
            </DialogActions>
          </>
        )}
      </StyledDialog>
    </DialogContext.Provider>
  )
}

export default DialogProvider
