import React from 'react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { analyticsClient } from '@straetus/react/modules/analytics'
import rtlPlugin from 'stylis-plugin-rtl'

import { customThemeOptions, getThemeOptions, PaletteMode } from './theme'
import { ThemeContext } from './theme.hooks'

// Cache for the ltr version of the styles
export const cacheLtr = createCache({
  key: 'css',
  prepend: true
})
cacheLtr.compat = true

// Cache for the rtl version of the styles
const cacheRtl = createCache({
  key: 'css-rtl',
  prepend: true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  stylisPlugins: [rtlPlugin]
})
cacheRtl.compat = true

export default function ThemeProvider({
  children,
  LinkBehavior
}: React.PropsWithChildren<{ LinkBehavior?: any }>) {
  const [activeType, setType] = React.useState(localStorage.getItem('___straetus_theme_mode') || null)
  const [activeDirection, setDirection] = React.useState(localStorage.getItem('___straetus_theme_direction') || 'ltr')
  // Checks if the user wants darkMode or not
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const handleSwitchToDarkMode = React.useCallback(() => {
    setType('dark')
    localStorage.setItem('___straetus_theme_mode', 'dark')
  }, [])

  const handleSwitchToLightMode = React.useCallback(() => {
    setType('light')
    localStorage.setItem('___straetus_theme_mode', 'light')
  }, [])

  const handleSwitchToRtl = React.useCallback(() => {
    setDirection('rtl')
    localStorage.setItem('___straetus_theme_direction', 'rtl')
  }, [])

  const handleSwitchToLtr = React.useCallback(() => {
    setDirection('ltr')
    localStorage.setItem('___straetus_theme_direction', 'ltr')
  }, [])

  const theme = React.useMemo(() => {
    let themeType = prefersDarkMode ? 'dark' : 'light' as PaletteMode

    if (activeType !== null) {
      themeType = activeType as PaletteMode

    } else if (prefersDarkMode) {
      handleSwitchToDarkMode()
    }

    // Will set this with the next event
    analyticsClient.lazySetPersonProperties({
      theme_mode: themeType
    })

    return createTheme(getThemeOptions(themeType, activeDirection, LinkBehavior), customThemeOptions)
  }, [prefersDarkMode, activeType, activeDirection, LinkBehavior, handleSwitchToDarkMode])

  const cacheProvider = React.useMemo(() => (
    activeDirection === 'rtl' ? cacheRtl : cacheLtr
  ), [activeDirection])

  const apiValue = React.useMemo(() => ({
    goDark: handleSwitchToDarkMode,
    goLight: handleSwitchToLightMode,
    goRtl: handleSwitchToRtl,
    goLtr: handleSwitchToLtr,
    isRtl: activeDirection === 'rtl',
    isDark: activeType !== null
      ? activeType === 'dark'
      : prefersDarkMode
  }), [
    handleSwitchToDarkMode,
    handleSwitchToLightMode,
    handleSwitchToLtr,
    handleSwitchToRtl,
    activeDirection,
    activeType,
    prefersDarkMode
  ])

  return (
    <ThemeContext.Provider value={apiValue}>
      <MuiThemeProvider theme={theme}>
        <CacheProvider value={cacheProvider}>
          {children}
        </CacheProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}
