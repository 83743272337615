import React from 'react'

import { Environment, EnvironmentContext } from './environment.context'
import { useSystemStatus } from './environment.hooks'

export interface EnvironmentProps {
  environment: Environment
}

/**
 * TODO:: Move to react/main/modules
 */
export default function EnvironmentProvider({
  environment,
  children
}: React.PropsWithChildren<EnvironmentProps>) {
  const system = useSystemStatus(environment.statusApi)

  return (
    <EnvironmentContext.Provider value={{
      ...environment,
      system
    }}>
      {children}
    </EnvironmentContext.Provider>
  )
}
