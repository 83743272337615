import { ApolloClient, ApolloLink, createHttpLink, defaultDataIdFromObject, from, InMemoryCache } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createApolloErrorLink } from '@straetus/react/modules/graphql'
import { createClient } from 'graphql-ws'

import { environment } from '../../environments'

function fetcherWithOperationQuery(uri: string, options) {
  const { operationName } = JSON.parse(options.body)

  return fetch(`${uri}?operation=${operationName}`, options)
}

const apiLink = createHttpLink({
  uri: environment.graphql.api,
  credentials: 'include',
  fetch: fetcherWithOperationQuery
})

const wsLink = new GraphQLWsLink(createClient({
  url: environment.graphql.apiWs,
  lazy: true,
  retryAttempts: 100,
  retryWait: async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 10000)
    })
  }
}))

const cmsLink = createHttpLink({
  uri: `${process.env.NX_PUBLIC_APP_CMS_URL || 'https://cms.straetus.app'}/graphql`,
  fetch: fetcherWithOperationQuery,
  headers: {
    Authorization: `Bearer ${process.env['NX_PUBLIC_APP_CMS_TOKEN'] || 'a11f7c2bdc5fd4465ab3202e5c7a2455197b555f775e78833f3057c17a994fa65dc37d8ea581d0979287012cf3fb24a3a308f370df5541197ba660e39d916b737f89054996445d834ce2fb906ecaca6b9c96b1781fa5c8df0f94b21d77bbb05f493fc3ebdebe9c1a192e23b91b7e29c7bf3b72bb7fd27d154b1773ca79d28f41'}`
  }
})

export const createApolloClient = (handleReAuthentication) => (
  new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    link: from([
      createApolloErrorLink(handleReAuthentication),
      ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query)

          return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription')
        },
        wsLink,
        ApolloLink.split(
          (operation) => operation.getContext().isCms,
          cmsLink,
          apiLink
        )
      )
    ]),
    cache: new InMemoryCache({
      addTypename: true,
      dataIdFromObject(responseObject) {
        if (responseObject.__typename.endsWith('Audit') && responseObject._seq) {
          // For audit records we want to use the sequence instead
          return `${responseObject.__typename}:${responseObject._seq}`
        }

        return defaultDataIdFromObject(responseObject)
      }
    }),
    defaultOptions: {
      mutate: {
        errorPolicy: 'all'
      }
    }
  })
)
