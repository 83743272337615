export enum CompanyType {
  // Only used internally
  International = 'international',

  Master = 'master',
  Franchisee = 'franchisee',
  Client = 'client'
}

export enum CompanyVariant {
  Person = 'PERSON',
  Company = 'COMPANY',
}

/**
 * Determines what configs to use
 */
export enum CompanyEnv {
  Prod = 'PROD',
  Test = 'TEST',
}

export enum Feature {
  SepaBatchWithInvoicing = 'SEPA_BATCH_WITH_INVOICING'
}

/**
 * Mapping countries to their ISO code for easier use
 */
export enum CompanyCountryISO {
  Netherlands = 'NL',
  Poland = 'PL',
  Romania = 'RO',
  Germany = 'DE',
  Belgium = 'BE',
  Austria = 'AT',
  Sweden = 'SE',
  Luxembourg = 'LU',
  Denmark = 'DK'
}
