/* eslint-disable simple-import-sort/imports */
import './mui-license'
/* eslint-enable simple-import-sort/imports */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import CssBaseline from '@mui/material/CssBaseline'
import ErrorBoundary from '@straetus/react/components/error-boundary'
import FullScreenLoader from '@straetus/react/components/full-screen-loader'
import AnalyticsProvider from '@straetus/react/modules/analytics'
import DialogProvider from '@straetus/react/modules/dialog'
import EnvironmentProvider from '@straetus/react/modules/environment'
import LanguageProvider, { Locale } from '@straetus/react/modules/language'
import SnackbarProvider from '@straetus/react/modules/snackbar'
import ThemeProvider from '@straetus/react/theme'
import posthog from 'posthog-js'

import packageJson from '../package.json'
import LinkBehavior from './modules/link-behavior.component'
import { environment } from './environments'
import ApolloProvider from './modules/graphql/apollo.provider'
import { loadMessages } from './modules/languages.utils'
import VersionProvider from './modules/version'
import Routes from './routes'
import supportedLocales from './translations/locales.json'

posthog.init(
  'phc_nCJD48H7tXWca7DZwlCAlAVFkIkG3WkAlhYJxbxocxC',
  {
    api_host: 'https://eu.posthog.com',
    // Disable automatic pageview capture, as we capture manually
    capture_pageview: false,
    disable_session_recording: true,
    before_send: (captureResult) => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('Would have sent event:', captureResult)

        return null
      }

      return captureResult
    },
    loaded: (posthogInstance) => {
      posthogInstance.setPersonProperties({
        app_version: packageJson.version
      })
    }
  }
)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CompatRouter>
        <ThemeProvider LinkBehavior={LinkBehavior}>
          <React.Suspense fallback={<FullScreenLoader withDelay />}>
            <EnvironmentProvider environment={environment}>
              <AnalyticsProvider disablePageview>
                <CssBaseline />

                <LanguageProvider
                  loadMessages={loadMessages}
                  supportedLocales={supportedLocales as Locale[]}>
                  <ErrorBoundary
                    apiKey={environment.errorReportingApiKey}
                    projectId={'straetus-app'}
                    service={packageJson.name}
                    version={packageJson.version}
                    withGoToDashboard>
                    <ApolloProvider>
                      <SnackbarProvider>
                        <VersionProvider>
                          <DialogProvider>
                            <Routes />
                          </DialogProvider>
                        </VersionProvider>
                      </SnackbarProvider>
                    </ApolloProvider>
                  </ErrorBoundary>
                </LanguageProvider>
              </AnalyticsProvider>
            </EnvironmentProvider>
          </React.Suspense>
        </ThemeProvider>
      </CompatRouter>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('load', () => {
    // https://felixgerschau.com/how-to-make-your-react-app-a-progressive-web-app-pwa/
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
    }
  })
}

// Initialize deferredPrompt for use later to show browser install prompt.
// Let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (e) => {
//   // Prevent the mini-infobar from appearing on mobile
//   e.preventDefault();
//   // Stash the event so it can be triggered later.
//   deferredPrompt = e;
//   // Update UI notify the user they can install the PWA
//   // showInstallPromotion();
//   // Optionally, send analytics event that PWA install promo was shown.
//   console.log(`'beforeinstallprompt' event was fired.`);
// })
