import React from 'react'

import type { GraphQLError, GraphQLFormattedError } from 'graphql/index'

import { DeleteSnackbarContext, SnackbarContext } from './snackbar.context'

export enum SnackbarDuration {
  Normal = 5000,
  Longer = 6000,
  Indefinitely = -1
}

export interface Options {
  id?: string
  action?: (close: () => void) => React.ReactNode
  duration?: SnackbarDuration
  title?: string
  severity?: 'error' | 'warning' | 'info' | 'success'
}

export type ErrorMessages = {
  [key: string]: {
    id: string
    defaultMessage: string
  }
}

export type SnackbarContext = (message: string, options?: Options) => void
export type DeleteSnackbarApi = (type: string) => {
  complete: () => void
  error: (errors?: ReadonlyArray<GraphQLError | GraphQLFormattedError>, messages?: ErrorMessages) => void
}

export function useSnackbar() {
  return React.useContext(SnackbarContext) as SnackbarContext
}

export function useDeleteSnackbar() {
  return React.useContext(DeleteSnackbarContext) as DeleteSnackbarApi
}
