import { Language } from '@straetus/react/interfaces'
import { addMessagesToCache, getDateMessages } from '@straetus/react/modules/language'

function fetchTranslations(languageCode: string) {
  return fetch(`https://cdn.simplelocalize.io/7d6ef1ff99e54d76a241e79ecd8e2d7e/_latest/${languageCode.toLowerCase()}`)
    .then((response) => response.json())
    .catch(() => import('../translations/en.json'))
}

export async function loadMessages(language: Language) {
  const dateMessages = await getDateMessages(language)
  const messages = await fetchTranslations(language)

  addMessagesToCache(language, messages, dateMessages)

  return messages
}
