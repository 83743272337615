import React from 'react'
import { useIntl } from 'react-intl'

export function useIntlFormatCurrency() {
  const intl = useIntl()

  return React.useCallback((centAmount: number, currency: string) => {
    return intl.formatNumber(centAmount / 100, {
      style: 'currency',
      currency
    })
  }, [intl])
}
