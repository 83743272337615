import React from 'react'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Skeleton from '@straetus/react/components/skeleton'
import {
  StraetusLogoIcon,
  straetusLogoIconClasses,
  StraetusLogoIconProps
} from '@straetus/react/components/straetus-icons'

export interface IllustrationContainerProps {
  title: string | React.ReactNode
  illustration: string
  illustrationAlt?: string
  illustrationWidth?: string
  withLogo?: boolean
  logoProps?: StraetusLogoIconProps

  skeleton?: boolean
}

export const StyledDiv = styled('div')(({ theme }) => ({
  margin: theme.spacing(2),
  minHeight: `calc(100dvh - ${theme.spacing(4)})`
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minHeight: `calc(100dvh - ${theme.spacing(4)})`,
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden'
}))

export const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: 400,
  width: '100%',
  display: 'flex',
  margin: 'auto',
  flexDirection: 'column',

  [`& .${straetusLogoIconClasses.root}`]: {
    textAlign: 'center',
    width: '100%',

    '@media only screen and (max-height: 630px)': {
      display: 'none'
    },

    '@media only screen and (max-height: 800px)': {
      marginTop: -200,

      [`& .${straetusLogoIconClasses.icon}`]: {
        display: 'none'
      }
    },

    '@media only screen and (min-height: 800px)': {
      marginTop: -125,
      marginBottom: theme.spacing(2)
    },

    [theme.breakpoints.down('md')]: {
      marginTop: -200,

      [`& .${straetusLogoIconClasses.icon}`]: {
        display: 'none'
      }
    }
  }
}))

export const StyledIllustrationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',

  '& img': {
    maxWidth: 600
  }
}))

export default function IllustrationContainer({
  children,
  title,
  illustration,
  illustrationAlt,
  illustrationWidth = '70%',
  skeleton = false,
  withLogo = false,
  logoProps = {}
}: React.PropsWithChildren<IllustrationContainerProps>) {
  return (
    <Grid container>
      <Grid
        size={{
          md: 6,
          xs: 12
        }}>
        <Fade in>
          <StyledDiv>
            <StyledPaper>
              <StyledContainer>
                {withLogo && (
                  <StraetusLogoIcon {...logoProps} />
                )}

                <Skeleton enabled={skeleton}>
                  <Typography
                    sx={{ mb: 2 }}
                    textAlign={'left'}
                    variant={'h5'}
                    width={'100%'}>
                    {title}
                  </Typography>
                </Skeleton>

                {children}
              </StyledContainer>
            </StyledPaper>
          </StyledDiv>
        </Fade>
      </Grid>

      <Grid
        size={{
          md: 6
        }}
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}>
        <StyledIllustrationContainer>
          <Fade
            key={illustration}
            in>
            <img
              alt={`${illustrationAlt || title} illustration`}
              src={illustration}
              width={illustrationWidth} />
          </Fade>
        </StyledIllustrationContainer>
      </Grid>
    </Grid>
  )
}
