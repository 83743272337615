import { matchPath } from 'react-router-dom'

export function getCurrentCompanyFromUrl(): string | boolean {
  const activeUrl = new URL(window.location.href)
  const [start, currentCompany] = activeUrl.pathname.split('/')

  if (currentCompany) {
    return currentCompany
  }

  return false
}

export function getCurrentUrlWithoutCompany(): string {
  const activeUrl = new URL(window.location.href)
  const [start, currentCompany, ...currentPath] = activeUrl.pathname.split('/')

  // If we are nowhere then go to dashboard
  if (currentPath.length === 0) {
    currentPath.push('dashboard')
  }

  return `/${currentPath.join('/')}`
}

const dontRedirectThesePaths = [
  '/login',
  '/login/code',
  '/login/reset',
  '/activate',
  '/forgot-password',
  '/account/:tab?'
]

export function isNotRedirectableUrl(url: string) {
  return dontRedirectThesePaths.some((doNotRedirectPath) => matchPath(url, {
    path: doNotRedirectPath
  }))
}
