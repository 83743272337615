import React from 'react'
import { createContextualCan } from '@casl/react'
import { Ability, subject } from '@casl/ability'

export const AbilityContext = React.createContext<Ability>(null as never)

export const Can = createContextualCan(AbilityContext.Consumer)

export {
  subject
}
