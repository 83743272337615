import { chipClasses } from '@mui/material/Chip'

import type { Theme as MuiTheme, ThemeOptions } from '@mui/material/styles'

import { shadowsDark, shadowsLight } from './shadows'

export type PaletteMode = 'light' | 'dark'
export type Theme = MuiTheme

declare module '@mui/material/styles' {
  interface Theme {
    drawerWidth: string
    drawerClosedWidth: string
    slideInDrawerWidth: string
    toolbarHeight: number
  }

  interface Palette {
    third: {
      main: string
      contrastText: string
    }

    custom: {
      secondary: string
      border: string
      third: string
      shadow: string
    }
  }

  interface PaletteOptions {
    third: {
      main: string
      contrastText: string
    }

    custom: {
      secondary: string
      third: string
      border: string
      shadow: string
    }
  }

}

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    'extra-small': true
  }

  interface ChipPropsColorOverrides {
    third: true
  }
}

declare module '@mui/material/Toolbar' {
  interface ToolbarPropsVariantOverrides {
    'custom': true
  }
}

export const customThemeOptions = {
  drawerWidth: '240px',
  drawerClosedWidth: '64px',
  slideInDrawerWidth: '500px',
  toolbarHeight: 56
}

// https://bareynol.github.io/mui-theme-creator
export const getThemeOptions = (
  mode: PaletteMode = 'light',
  direction = 'ltr',
  LinkBehavior?: any
): ThemeOptions => ({

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  direction,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1328,
      xl: 1536,
    }
  },

  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'sans-serif',
      'Roboto'
    ].join(','),

    h1: {
      fontSize: 48,
      lineHeight: 1.5
    },

    h2: {
      fontSize: 36,
      lineHeight: 1.5
    },

    h3: {
      fontSize: 32,
      lineHeight: 1.5
    },

    h4: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 600
    },

    h5: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 600
    },

    h6: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600
    },

    body1: {
      fontSize: 16,
      lineHeight: 1.5
    },

    subtitle1: {
      fontSize: 16,
      lineHeight: 1.75,
      fontWeight: 400
    },

    subtitle2: {
      fontSize: 14,
      lineHeight: 1.75,
      fontWeight: 500
    },

    caption: {
      fontSize: 12,
      lineHeight: 1.6
    },

    overline: {
      fontSize: 12,
      lineHeight: 2.46
    }
  },

  palette: {
    mode,

    ...(mode === 'dark' && ({
      text: {
        primary: 'rgb(227, 227, 227)'
      },

      background: {
        paper: 'rgb(3, 6, 11)',
        default: 'rgb(3, 6, 11)'
      }
    })),

    primary: {
      main: '#4b9560'
    },

    secondary: {
      main: '#0288d1'
    },

    third: {
      main: '#b9975b',
      contrastText: '#fff'
    },

    divider: mode === 'light'
      ? 'rgba(0, 0, 0, 0.05)'
      : 'rgba(255, 255, 255, 0.11)',

    custom: {
      secondary: '#002f6c',
      third: '#b9975b',

      border: mode === 'light'
        ? 'rgb(208, 213, 221)'
        : 'rgba(255, 255, 255, 0.16)',

      shadow: 'rgb(9 30 66 / 8%) 0px 1px 2px 0px'
    }
  },

  shape: {
    borderRadius: 6
  },

  shadows: mode === 'light'
    ? shadowsLight
    : shadowsDark,

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          backgroundColor: mode === 'dark'
            ? 'rgb(3, 6, 11)'
            : '#f1f5f9',

          WebkitFontSmoothing: 'auto'
        },
        body: {
          minHeight: '100vh',
          backgroundColor: mode === 'dark'
            ? 'rgb(3, 6, 11)'
            : '#f1f5f9',

          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale'
        }
      }
    },

    MuiLink: {
      defaultProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: LinkBehavior
      },
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },

    MuiButton: {
      defaultProps: {
        size: 'small',
        LinkComponent: LinkBehavior
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },

    MuiIconButton: {
      defaultProps: {
        size: 'small',
        LinkComponent: LinkBehavior
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize'
        }
      }
    },

    MuiButtonGroup: {
      defaultProps: {
        size: 'small'
      }
    },

    MuiCheckbox: {
      defaultProps: {
        size: 'small'
      }
    },

    MuiFab: {
      defaultProps: {
        size: 'small',
        LinkComponent: LinkBehavior
      }
    },

    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small'
      }
    },

    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense'
      }
    },

    MuiInputBase: {
      defaultProps: {
        margin: 'dense'
      }
    },

    MuiInputLabel: {
      defaultProps: {
        margin: 'dense'
      }
    },

    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
        size: 'small'
      },
      styleOverrides: {
        input: ({ theme }) => ({
          padding: theme.spacing('6px', 1.5),
          fontSize: 14,
          lineHeight: 1.6,
          height: 'unset'
        }),

        notchedOutline: {
          boxShadow: 'rgb(9 30 66 / 8%) 0px 1px 2px 0px',
          borderColor: mode === 'light'
            ? 'rgb(208, 213, 221)'
            : 'rgba(255, 255, 255, 0.16)'
        }
      }
    },

    MuiRadio: {
      defaultProps: {
        size: 'small'
      }
    },

    MuiSwitch: {
      defaultProps: {
        size: 'small'
      }
    },

    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small'
      }
    },

    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      },
      styleOverrides: {
        root: {
          padding: '0px 4px'
        }
      }
    },

    MuiMenuItem: {
      defaultProps: {
        dense: true
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
          margin: '4px 0px'
        }
      }
    },

    MuiList: {
      defaultProps: {
        dense: true
      },
      styleOverrides: {
        root: {
          padding: '1px 4px'
        }
      }
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          margin: '0 -4px',
          paddingLeft: 20,
          paddingRight: 20
        }
      }
    },

    MuiListItem: {
      defaultProps: {
        dense: true
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
          margin: '4px 0px'
        }
      }
    },

    MuiListItemButton: {
      defaultProps: {
        dense: true
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: '4px 4px'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          padding: '4px 4px'
        }
      }
      // defaultProps: {
      //   popupIcon: <ExpandMoreRoundedIcon fontSize={'small'} />,
      //   clearIcon: <ClearRoundedIcon fontSize={'small'} />
      // }
    },
    // MuiSelect: {
    //   defaultProps :{
    //     IconComponent: ArrowDropDownRoundedIcon
    //   }
    // },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small'
      }
    },

    MuiChip: {
      defaultProps: {
        size: 'small'
      },
      variants: [
        {
          props: { size: 'extra-small' },
          style: ({ theme }) => ({
            ...theme.typography.caption,
            height: theme.spacing(2),

            '& span': {
              padding: theme.spacing(0, 0.5)
            },

            [`& .${chipClasses.icon}`]: {
              width: 12,
              marginRight: 0
            },

            [`& .${chipClasses.deleteIcon}`]: {
              width: 12,
              margin: 0
            }
          })
        }
      ]
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },

    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 14,
          color: theme.palette.text.primary
        })
      }
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px 16px',
          gap: 8
        }
      }
    },

    MuiToolbar: {
      defaultProps: {
        variant: 'custom'
      },
      variants: [
        {
          props: { variant: 'custom' },
          style: {
            height: customThemeOptions.toolbarHeight
          }
        }
      ]
    },

    MuiLinearProgress: {
      styleOverrides: {
        determinate: {
          borderRadius: 6
        }
      }
    }
  }
})
