import type { Environment } from '@straetus/react/modules/environment'

export const environment: Environment = {
  production: true,

  errorReportingApiKey: 'AIzaSyD8lBtcxBOYBOD9dSL5lMcmDjFe1EtaqvY',

  statusApi: 'https://functions.straetus.app/system-uptime',
  statusDashboard: 'https://status.straetus.app',

  docsUrl: 'https://straetus.help',
  debtorPortalUrl: 'https://pay.straetus.app',

  overheidIoApiKey: '0dbac199be3ccd1e485d0de24ba5c667029db99ca0e5baef7ec58c5ba1ca310a',

  graphql: {
    api: 'https://apis.straetus.app/main/graphql',
    apiWs: 'wss://apis.straetus.app/notifications/graphql?type=subscriptions'
  }
}
