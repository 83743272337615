import React from 'react'
import { defineMessage } from 'react-intl'

export interface FeedItem {
  eventType: 'ann' | 'info'
  severity: 'info' | 'warning' | 'error'
  ts: number
  title: string
  content: string
}

export interface State {
  status: 'fetching' | 'operational' | 'partlyDown' | 'down' | 'offline' | 'maintenance'
  feed: FeedItem[]
  message: {
    id: string
    defaultMessage: string
  }
}

export function useSystemStatus (statusApi: string): State {
  const getStatusMessage = React.useCallback(((status: State['status']) => {
    switch (status) {
      case 'fetching':
        return defineMessage({
          id: 'menu.system-status.fetching',
          defaultMessage: 'Fetching system status'
        })

      case 'operational':
        return defineMessage({
          id: 'menu.system-status.operational',
          defaultMessage: 'All systems operational'
        })

      // Betterstack does not have "partlyDown"
      case 'down':
      case 'partlyDown':
        return defineMessage({
          id: 'menu.system-status.partlyDown',
          defaultMessage: 'Some systems down'
        })

      // case 'down':
      //   return defineMessage({
      //     id: 'menu.system-status.down',
      //     defaultMessage: 'All systems down'
      //   })

      case 'maintenance':
        return defineMessage({
          id: 'menu.system-status.maintenance',
          defaultMessage: 'System undergoing maintenance'
        })

      case 'offline':
      default:
        return defineMessage({
          id: 'menu.system-status.offline',
          defaultMessage: 'Unable to get system status'
        })
    }
  }), [])

  const [state, setState] = React.useState<State>({
    status: 'fetching',
    message: getStatusMessage('fetching'),
    feed: []
  })

  const setOffline = React.useCallback(() => {
    setState({
      status: 'offline',
      message: getStatusMessage('offline'),
      feed: []
    })
  }, [getStatusMessage])

  React.useEffect(() => {
    const fetchStatus = async () => {
      try {
        const { status } = await fetch(statusApi)
          .then((response) => response.json())

        if (status === 'offline') {
          return setOffline()
        }

        setState({
          status: status,
          feed: [],
          message: getStatusMessage(status)
        })
      } catch {
        setOffline()
      }
    }

    if (statusApi) {
      fetchStatus()
    }
  }, [getStatusMessage, setOffline, statusApi])

  return state
}
