import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ApolloProvider as ApolloClientProvider } from '@apollo/client'

import { isNotRedirectableUrl } from '../user/user.utils'
import { createApolloClient } from './apollo.client'

export default function ApolloProvider({ children }) {
  const location = useLocation()
  const history = useHistory()

  // Redirect the user to the login screen when session is expired
  const handleReAuthentication = React.useCallback(() => {
    if (!isNotRedirectableUrl(location.pathname)) {
      history.push('/login', {
        redirect: location.pathname !== '/'
          ? location.pathname
          : undefined
      })
    }
  }, [history, location.pathname])

  const client = React.useRef(createApolloClient(handleReAuthentication))

  return (
    <ApolloClientProvider client={client.current}>
      {children}
    </ApolloClientProvider>
  )
}
