import type { GraphQLError, GraphQLFormattedError } from 'graphql'

type Errors = readonly GraphQLError[] | readonly GraphQLFormattedError[]

export function getGlobalGraphQLErrorCode(errors: Errors): string {
  if (errors.length === 1 && errors[0]) {
    const graphQLError = errors[0]

    if (graphQLError.extensions && graphQLError.extensions.code) {
      return graphQLError.extensions.code as string
    }
  }

  return ''
}

export function getGraphqlValidationError(errors: Errors): { [key: string]: string } {
  if (
    errors.length === 1
    && errors[0].extensions.code === 'api.validation'
  ) {
    const graphQLError = errors[0]

    if (graphQLError.extensions.fields) {
      return graphQLError.extensions.fields as { [key: string]: string }
    }
  }

  return {}
}
