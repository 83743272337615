import React from 'react'
import { styled } from '@mui/material/styles'
import { StraetusLogoIcon } from '@straetus/react/components/straetus-icons'
import { useOnMountEffect } from '@straetus/react/modules/utils'

export interface FullScreenLoaderProps {
  withDelay?: boolean
}

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.mode === 'light'
    ? theme.palette.custom.secondary
    : theme.palette.background.default,
  zIndex: theme.zIndex.modal + 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'

}))

function FullScreenLoader({ withDelay = false }: FullScreenLoaderProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const [visible, setVisible] = React.useState(!withDelay)

  useOnMountEffect(() => {
    let timeout: NodeJS.Timeout

    if (withDelay) {
      // If the app takes longer then 150ms then show the loader
      timeout = setTimeout(() => setVisible(true), 150)
    }

    return () => {
      clearTimeout(timeout)
    }
  })

  if (!visible) {
    return <div ref={ref} />
  }

  return (
    <Root
      ref={ref}
      data-test-id={'components.full-screen-loader'}>
      <StraetusLogoIcon
        color={'inherit'}
        withSlogan={false}
        withText={false}
        style={{
          color: 'white'
        }}
        sx={{
          fontSize: 200
        }}
        specialEdition
      />
    </Root>
  )
}

export default React.forwardRef(FullScreenLoader)
