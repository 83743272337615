import { gql } from '@apollo/client'

export const MeQuery = gql`
  query me{
    me {
      id
      firstName
      lastName
      fullName
      email
      language
      type
      scopes
      hasTwoFactor

      features {
        voip
        defaultVoipExtension
      }
    }

    activeCompany {
      id
      name
      slug
      type
      country
      currency
      env

      features {
        iso20022TransactionsImport
        openKvkSearch
      }
    }
  }
`
