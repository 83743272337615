import { Ability, AbilityBuilder, AbilityClass, createAliasResolver } from '@casl/ability'
import { Action, Claim, ScopeAction } from '@straetus/constants/auth'
import { CompanyType } from '@straetus/constants/company'

export type AppAbility = Ability<[Action, Claim]>

/**
 * In case we want aliases
 * https://casl.js.org/v5/en/guide/define-aliases
 */

export function buildAbility(abilityBuilder: AbilityBuilder<AppAbility>): AppAbility {
  return abilityBuilder.build({
    resolveAction: createAliasResolver({
      [ScopeAction.Write]: [Action.Create, Action.Update, Action.Read]
    })
  })
}

export function createCaslAbilityBuilder(userId?: string, userType?: string, stringCompanyType?: CompanyType | string | null, scopes?: string[]) {
  const abilityBuilder = new AbilityBuilder<AppAbility>(Ability as AbilityClass<AppAbility>)

  if (!userType && !stringCompanyType && (!scopes || scopes.length === 0)) {
    return abilityBuilder
  }

  const companyType = (stringCompanyType || '').toLowerCase() as CompanyType // Make sure that the GraphQL string is also valid CompanyType

  // First add everything the user is allowed to do
  scopes.forEach((scope) => {
    const [action, claim] = scope.split(':')

    abilityBuilder.can(action as Action, claim as Claim)
  })

  // If the company type is client then skip the rest as all the scopes
  // are set for the client in me.service.ts
  if (companyType === CompanyType.Client) {
    return abilityBuilder
  }

  const adminScope = `${Action.Manage}:${Claim.Admin}`
  // If the user has the admin scope or is international user they can do everything
  if (scopes.includes(adminScope) || companyType === CompanyType.International) {
    // read-write access to everything
    abilityBuilder.can(Action.Manage, Claim.All)
  }

  // Certain users are allowed alpha features
  // prod - local
  if (![
    // International user in E2E tests
    'b8c9dab3-29e8-5a1b-8d98-0a20e1b53690',
    // Tycho
    'd273b5a3-6d83-486f-9003-bb1b8736293a',
    'ca50a69f-9d84-49d2-910d-6a60b42e8e3a',
    // Dennis
    'e86f21d5-23b0-4bd0-af62-4ef61a73047c',
    // Marrit
    '7ec6236a-7f79-4a9e-b661-32ff37f61c1e'
  ].includes(userId)) {
    abilityBuilder.cannot(Action.Manage, Claim.AlphaFeatures)
  }

  // Every non-client is allowed to use beta features
  abilityBuilder.can(Action.Manage, Claim.BetaFeatures)

  // Remove what international companies are not allowed to do
  if (companyType === CompanyType.International) {
    abilityBuilder.cannot(Action.Create, Claim.Workflow)
    abilityBuilder.cannot(Action.Create, Claim.BankTransaction)
    abilityBuilder.cannot(Action.Create, Claim.Job)
    abilityBuilder.cannot(Action.Create, Claim.BusinessActivity)
  }

  // Remove what international and master companies are not allowed to do
  if ([CompanyType.International, CompanyType.Master].includes(companyType)) {
    abilityBuilder.cannot(Action.Create, Claim.Dossier)
    abilityBuilder.cannot(Action.Delete, Claim.Dossier)
    abilityBuilder.cannot(Action.Create, Claim.DossierInvoice)
    abilityBuilder.cannot(Action.Update, Claim.DossierInvoice)
    abilityBuilder.cannot(Action.Delete, Claim.DossierInvoice)
    abilityBuilder.cannot(Action.Create, Claim.DossierFile)
    abilityBuilder.cannot(Action.Update, Claim.DossierFile)
    abilityBuilder.cannot(Action.Delete, Claim.DossierFile)
    abilityBuilder.cannot(Action.Create, Claim.DossierLabel)
    abilityBuilder.cannot(Action.Update, Claim.DossierLabel)
    abilityBuilder.cannot(Action.Delete, Claim.DossierLabel)
    abilityBuilder.cannot(Action.Create, Claim.Client)

    abilityBuilder.cannot(Action.Create, Claim.CompanyLabel)
    abilityBuilder.cannot(Action.Update, Claim.CompanyLabel)
    abilityBuilder.cannot(Action.Delete, Claim.CompanyLabel)

    // Now allowed to create labels
    abilityBuilder.cannot(Action.Create, Claim.Label)
  }

  // Remove what all non-international companies are not allowed to do
  if (companyType !== CompanyType.International) {
    abilityBuilder.cannot(Action.Manage, Claim.Master)
    abilityBuilder.cannot(Action.Manage, Claim.CostCategory)
    abilityBuilder.can(Action.Read, Claim.CostCategory)

    // Only international can manage voip numbers/internal numbers
    abilityBuilder.can(Action.Manage, Claim.VoipInternalNumber)

    // Remove what all non-international and non-master companies are not allowed to do
    if (companyType !== CompanyType.Master) {
      abilityBuilder.cannot(Action.Manage, Claim.Franchisee)
      abilityBuilder.cannot(Action.Manage, Claim.BankTransaction)
      // They can only read jobs
      abilityBuilder.cannot(Action.Manage, Claim.Job)
      abilityBuilder.can(Action.Read, Claim.Job)
      abilityBuilder.can(Action.Read, Claim.JobFile)
      // They can only read business activities
      abilityBuilder.cannot(Action.Manage, Claim.BusinessActivity)
      abilityBuilder.can(Action.Read, Claim.BusinessActivity)

      // They can only read user groups
      abilityBuilder.cannot(Action.Manage, Claim.UserGroup)
      abilityBuilder.can(Action.Read, Claim.UserGroup)
      // They can only read voip extensions
      abilityBuilder.cannot(Action.Manage, Claim.VoipExtension)
      abilityBuilder.can(Action.Read, Claim.VoipExtension)
      // abilityBuilder.cannot(Action.Manage, Claim.Contract)
      // abilityBuilder.cannot(Action.Manage, Claim.CompanyContract)

      // They can only read features
      abilityBuilder.cannot(Action.Manage, Claim.CompanyFeature)
      abilityBuilder.can(Action.Read, Claim.CompanyFeature)

      // They can only read company area codes
      abilityBuilder.cannot(Action.Manage, Claim.CompanyAreaCode)
      abilityBuilder.can(Action.Read, Claim.CompanyAreaCode)

      // They can only read them, so first say they cannot manage but can read
      abilityBuilder.cannot(Action.Manage, Claim.TaxRate)
      abilityBuilder.can(Action.Read, Claim.TaxRate)
    }
  }

  return abilityBuilder
}
