export enum Gender {
  Man = 'MAN',
  Woman = 'WOMAN',
  Unknown = 'UNKNOWN'
}

export enum CompanyContactEmailStatus {
  Unknown = 'UNKNOWN',
  Valid = 'VALID',
  Invalid = 'INVALID'
}
