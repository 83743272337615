import React from 'react'
import { useHistory } from 'react-router-dom'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useQuery } from '@apollo/client'
import { useErrorReporter } from '@straetus/react/components/error-boundary'
import { useAuthApi } from '@straetus/react/modules/auth'
import { useFocusQuery } from '@straetus/react/modules/graphql'
import { BETA_FEATURES_STORAGE_KEY } from '@straetus/react/modules/user'
import { usePostHog } from 'posthog-js/react'

import type { Query } from '@straetus/react/interfaces'

import { MeQuery } from './user.graphql'
import { getCurrentCompanyFromUrl, getCurrentUrlWithoutCompany, isNotRedirectableUrl } from './user.utils'

export function useAutoAuthRedirect() {
  const navigate = useNavigate()
  const meQuery = useQuery<Pick<Query, 'me' | 'activeCompany'>>(MeQuery, {
    fetchPolicy: 'cache-first'
  })

  React.useEffect(() => {
    if (meQuery.data?.me) {
      if (meQuery.data.activeCompany) {
        navigate(`/${meQuery.data.activeCompany.slug}/dashboard`)

      } else {
        navigate('/select')
      }
    }
  }, [meQuery, navigate])
}

export function useMeQueryWithAutoCompanySwitch() {
  const errorReporting = useErrorReporter()
  const posthog = usePostHog()
  const history = useHistory()

  const [selectCompany] = useAuthApi('companyToken')

  const [userLoading, setUserLoading] = React.useState(true)
  const identifiedCompanySlug = React.useRef('')

  const meQuery = useFocusQuery<Pick<Query, 'me' | 'activeCompany'>>(MeQuery, {
    fetchPolicy: 'cache-and-network'
  })

  React.useEffect(() => {
    if (meQuery.data?.me) {
      const { me, activeCompany } = meQuery.data

      // Set the Google Analytics id
      errorReporting.setUser(me.id)
      posthog?.identify(me.id, {
        beta_mode: localStorage.getItem(BETA_FEATURES_STORAGE_KEY) ? 'enabled' : 'disabled'
      })

      const currentCompany = getCurrentCompanyFromUrl()
      if (currentCompany === 'ac') {
        if (activeCompany) {
          // Go to the correct active company
          // replace the state
          history.replace(`/${activeCompany.slug}${getCurrentUrlWithoutCompany()}`)

          // Replacing the state to active company, no longer loading
          // as active company was correct
          setUserLoading(false)
        } else {
          // Go select a company
          history.replace('/select')
        }
      } else if (isNotRedirectableUrl(`/${currentCompany}`)) {
        // Do nothing

      } else if (!activeCompany || activeCompany.slug !== currentCompany) {
        // Set loading back to true, this will prevent
        setUserLoading(true)

        // Select the correct company
        selectCompany(currentCompany as string).then(({ error }) => {
          if (error) {
            // We can ignore some errors, page itself will handle it with the correct redirect
            if (!error.code || !['api.no-access'].includes(error.code)) {
              history.push('/select')
            }

          } else {
            // Re-fetch the active user
            meQuery.refetch()
          }
        })

      } else if (activeCompany.slug !== identifiedCompanySlug.current) {
        posthog?.group('company', activeCompany.id, {
          name: activeCompany.name,
          type: activeCompany.type,
          env: activeCompany.env,
          country: activeCompany.country
        })

        // Prevents sending events when focus is back
        identifiedCompanySlug.current = activeCompany.slug
      }

      if (activeCompany && activeCompany.slug === currentCompany) {
        // We are no longer loading, prevents access leaking from one company to the other
        setUserLoading(false)
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [meQuery.data?.me, meQuery.data?.activeCompany])

  return userLoading ? null : meQuery.data
}
