export enum Claim {
  AlphaFeatures = 'alpha-features',
  BetaFeatures = 'beta-features',

  All = 'all',
  Me = 'me',
  Companies = 'companies',
  Sessions = 'sessions',
  Notification = 'notification',

  // Only added when user is defined as admin
  Admin = 'admin',

  User = 'user',
  UserGroup = 'user-group',
  // The companies a user is part of
  UserCompany = 'user-company',

  Dossier = 'dossier',
  DossierFile = 'dossier-file',
  DossierCost = 'dossier-cost',
  DossierNote = 'dossier-note',
  DossierLabel = 'dossier-label',
  // Invoices of dossiers
  DossierInvoice = 'dossier-invoice',

  Workflow = 'workflow',
  WorkflowStep = 'workflow-step',
  WorkflowAction = 'workflow-action',
  WorkflowPaymentArrangement = 'workflow-payment-arrangement',

  Transaction = 'transaction',
  BankTransaction = 'bank-transaction',

  Master = 'master',
  Franchisee = 'franchisee',
  Client = 'client',

  Audit = 'audit',

  CompanyPage = 'company-page',
  CompanyContact = 'company-contact',
  CompanyContract = 'company-contract',
  CompanyNote = 'company-note',
  CompanyFile = 'company-file',
  CompanyFeature = 'company-feature',
  CompanySettings = 'company-settings',
  CompanyAreaCode = 'company-area-code',
  CompanyBankAccount = 'company-bank-account',
  CompanyIntegration = 'company-integration',
  CompanyLabel = 'company-label',

  // Contract of master, franchisee or client
  Contract = 'contract',

  BusinessActivity = 'business-activity',

  // Generated invoices for master, franchisee or client
  Invoice = 'invoice',
  InvoiceLine = 'invoice-line',
  RepeatingInvoiceLine = 'repeating-invoice-line',
  InvoiceFile = 'invoice-file',

  Job = 'job',
  JobFile = 'job-file',

  TaxRate = 'tax-rate',
  InterestRate = 'interest-rate',
  CostCategory = 'cost-category',

  Label = 'label',

  // Voip
  Voip = 'voip',
  VoipNumber = 'voip-number',
  VoipInternalNumber = 'voip-internal-number',
  VoipExtension = 'voip-extension',
  VoipCall = 'voip-call',
  VoipCallRecording = 'voip-call-recording'
}
