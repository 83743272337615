import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps, Path } from 'react-router-dom-v5-compat'

interface LinkBehaviorProps extends RouterLinkProps {
  href: RouterLinkProps['to']
}

function buildHref(href: string | Partial<Path> = ''): string | Partial<Path> {
  const to = typeof href === 'string' ? href : href.pathname

  if (to.includes('/ac/')) {
    const [_, activeCompanySlug] = window.location.pathname.split('/')

    return to.replace('/ac/', `/${activeCompanySlug}/`)
  }

  return href
}

function LinkBehavior({
  href,
  to: toProp,
  ...other
}: LinkBehaviorProps, ref) {
  return (
    <RouterLink
      ref={ref}
      to={buildHref(toProp || href)}
      {...other} />
  )
}

export default React.forwardRef(LinkBehavior)
