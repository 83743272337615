import axios, { Axios, AxiosError } from 'axios'

import type { ApiAuthLoginParams, ApiAuthLoginResponse, ApiAuthVoidResponse } from './auth-api.interfaces'

export class AuthApiClient {

  private readonly client: Axios

  constructor(
    private readonly clientId: string = process.env.NX_PUBLIC_AUTH_CLIENT_ID || 'straetus-app',
    private readonly url: string = process.env.NX_PUBLIC_AUTH_API_URL || 'https://apis.straetus.app/auth'
  ) {
    if (!this.clientId) {
      throw new Error('No "clientId" setup!')
    }

    if (!this.url) {
      throw new Error('No "url" setup!')
    }

    this.client = axios.create({
      baseURL: url,
      withCredentials: true
    })
  }

  public async login({
    username,
    password,
    code,
    rememberComputer
  }: ApiAuthLoginParams): Promise<ApiAuthLoginResponse> {
    try {
      const { data } = await this.client.post('/token', {
        client_id: this.clientId,
        grant_type: 'password',
        username,
        password,
        code,
        rememberComputer
      })

      return {
        data
      }
    } catch (err) {
      return this.returnAxiosError<ApiAuthLoginResponse>(err as AxiosError)
    }
  }

  public async logout(): Promise<ApiAuthVoidResponse> {
    await this.client.post('/logout')

    return this.returnVoidResponse()
  }

  /**
   * For refreshing tokens we don't want to respond with a nice error object
   * so we can catch the error and correctly do our stuff
   */
  public async refreshTokens(): Promise<ApiAuthVoidResponse> {
    await this.client.post('/token', {
      client_id: this.clientId,
      grant_type: 'refresh_token'
    })

    return this.returnVoidResponse()
  }

  public async companyToken(slug: string): Promise<ApiAuthVoidResponse> {
    try {
      await this.client.post(`/company/${slug}/token`)

      return this.returnVoidResponse()
    } catch (err) {
      return this.returnAxiosError<ApiAuthVoidResponse>(err as AxiosError)
    }
  }

  private returnVoidResponse(): ApiAuthVoidResponse {
    return {
      data: null
    }
  }

  private returnAxiosError<R>(err: AxiosError): R {
    return {
      data: null,
      error: err.response?.data
    } as R
  }
}
