import React from 'react'

import type {
  ApiAuthLoginParams,
  ApiAuthLoginResponse,
  ApiAuthResponseError,
  ApiAuthVoidResponse
} from './auth-api.interfaces'

import { AuthApiClient } from './auth-api.client'

interface ApiAuthMethods {
  login: {
    params: [ApiAuthLoginParams]
    response: ApiAuthLoginResponse
  }
  logout: {
    params: []
    response: ApiAuthVoidResponse
  }
  refreshTokens: {
    params: []
    response: ApiAuthVoidResponse
  }
  companyToken: {
    params: [string]
    response: ApiAuthVoidResponse
  }
}

function isApiAuthErrorResponse(response: unknown): response is ApiAuthResponseError<string> {
  return Boolean(response && Boolean((response as ApiAuthResponseError<string>).error))
}

export function useAuthApi<
  Method extends keyof ApiAuthMethods,
  Params extends ApiAuthMethods[Method]['params'],
  Response extends ApiAuthMethods[Method]['response'],
>(method: Method): [
  (...args: Params) => Promise<Response>,
  {
    loading: boolean,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    error?: Response['error']['code'] | string
  }
] {
  const apiClient = React.useRef(new AuthApiClient())

  const [loading, setLoading] = React.useState(false)
  const [apiError, setApiError] = React.useState<string | undefined>(undefined)

  return React.useMemo(() => [
    async (...args: Params) => {
      setLoading(true)
      setApiError(undefined)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await apiClient.current[method](...args) as Response

      if (isApiAuthErrorResponse(response)) {
        setApiError(response.error.code)
      }

      setLoading(false)

      return response
    },
    {
      error: apiError,
      loading
    }
  ], [apiError, loading, method])
}
