import React from 'react'
import { useQuery } from '@apollo/client'

import type { OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode } from '@apollo/client'
import type { DocumentNode } from 'graphql'

const DEFAULT_INVALIDATE_AFTER = 10_000 // 10 seconds

/**
 * A wrapper around useQuery that will show stale data while loading.
 *
 * @param query - The GraphQL query to run
 * @param options - The query options, forwarded to useQuery
 * @returns The result of the query, with loading set to false even for stale data
 */
export function useFocusQuery<TData, TVariables extends OperationVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables> | DocumentNode,
  options?: QueryHookOptions<TData, TVariables> & {
    invalidateAfter?: number
  }
): QueryResult<TData, TVariables> {
  const { invalidateAfter = DEFAULT_INVALIDATE_AFTER } = options ?? {}

  const result = useQuery(query, options)
  const lastFetchAt = React.useRef(Date.now())

  if (result.loading) {
    const data = result.client.readQuery({
      query: query,
      variables: result.variables
    })

    if (data) {
      // Rewrite loading to false to show stale but fast data
      result.loading = false
    }
  }

  // This callback re-fetches the current query if it has not been re-fetched in the last N seconds.
  // We pass it to useOnFocus to re-fetch the query when the app regains focus.
  const onFocus = React.useCallback(() => {
    const diff = Date.now() - lastFetchAt.current
    if (diff > invalidateAfter) {
      lastFetchAt.current = Date.now()
      result.refetch()
    }
  }, [result, invalidateAfter])

  useOnFocus({
    onFocus: onFocus
  })

  return result
}

// TODO:: Move to somewhere we can use this?
function useOnFocus({ onFocus }: { onFocus: () => void }) {
  React.useEffect(() => {
    const handleFocus = () => {
      onFocus()
    }

    window.addEventListener('focus', handleFocus)
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [onFocus])
}
