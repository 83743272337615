import React from 'react'
import { useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import { SnackbarDuration, useSnackbar } from '@straetus/react/modules/snackbar'

export const VersionProvider = ({ children }) => {
  const showSnackbar = useSnackbar()
  const intl = useIntl()

  const handleNewVersionOfAppAvailable = React.useCallback(() => {
    showSnackbar(intl.formatMessage({
      id: 'snackbar.new-app-version-available',
      defaultMessage: 'There is an new version of the APP available!'
    }), {
      action: renderSnackbarAction(intl.formatMessage({
        id: 'btn.reload',
        defaultMessage: 'Reload'
      })),
      duration: SnackbarDuration.Indefinitely
    })
  }, [intl])

  const renderSnackbarAction = React.useCallback((label: string) => function() {
    return (
      <Button
        color={'inherit'}
        onClick={() => window.location.reload()}
        size={'small'}>
        {label}
      </Button>
    )
  }, [])

  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        handleNewVersionOfAppAvailable()
      })
    }
  }, [])

  return children
}

export default VersionProvider
