import React from 'react'
import { useLocation } from 'react-router'
import { PostHogProvider, usePostHog } from 'posthog-js/react'

import { analyticsClient } from './analytics.client'

export interface AnalyticsProviderProps {
  disablePageview?: boolean
}

export default function AnalyticsProvider({ children, disablePageview }: React.PropsWithChildren<AnalyticsProviderProps>) {
  const location = useLocation()
  const posthog = usePostHog()

  React.useEffect(() => {
    if (!disablePageview) {
      analyticsClient.capture('$pageview', {
        $current_url: window.location.href
      })
    }
  }, [location, posthog])

  return (
    <PostHogProvider client={posthog}>
      {children}
    </PostHogProvider>
  )
}
