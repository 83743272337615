// import { shouldPolyfill as shouldPolyfillRelativeTime } from '@formatjs/intl-relativetimeformat/should-polyfill'
import { Language } from '@straetus/react/interfaces'

import type { Locale } from 'date-fns'

import { LoadMessages } from './language.interfaces'

const STORAGE_KEY = '___straetus_interface_language'
const messagesCache = {} as {
  [key: string]: any
}

const supportedLanguageKeys = Object.keys(Language).map((key) => key.toLowerCase()) as string[]
const fallbackLanguage = Language.En.toLowerCase()

export function addMessagesToCache(language: Language, messages: any, dateMessages: any) {
  messagesCache[language] = {
    messages,
    dateMessages: dateMessages
  }
}

export function getMessagesFromCache(language: Language) {
  return messagesCache[language]
}

export function getInitialLocale() {
  const language = localStorage.getItem(STORAGE_KEY)

  if (!language && navigator.language) {
    let browserLanguage = navigator.language.toLowerCase().split('-').shift()

    // Check if the browser language is one we support
    if (browserLanguage && supportedLanguageKeys.includes(browserLanguage)) {
      if (browserLanguage === 'he') {
        browserLanguage = fallbackLanguage
      }

      return browserLanguage
    }
  }

  return language || fallbackLanguage
}

export function storeNewLocale(newLocale: string): void {
  return localStorage.setItem(STORAGE_KEY, newLocale)
}

export function getMessages(loadMessages: LoadMessages, language: Language) {
  const cache = getMessagesFromCache(language)

  if (cache) {
    return cache
  }

  // Suspense is based on ErrorBoundary
  // throwing a promise will cause <SomeLoadingComponent /> to render until the promise resolves
  throw loadMessages(language)
}

export async function getDateMessages(language: string): Promise<Locale> {
  switch (language) {
    case Language.Nl.toLowerCase():
      return (await import('date-fns/locale/nl')).nl

    case Language.De.toLowerCase():
      return (await import('date-fns/locale/de')).de

    case Language.Pl.toLowerCase():
      return (await import('date-fns/locale/pl')).pl

    case Language.Fr.toLowerCase():
      return (await import('date-fns/locale/fr')).fr

    case 'dk':
    case Language.Da.toLowerCase():
      return (await import('date-fns/locale/da')).da

    case Language.Ro.toLowerCase():
      return (await import('date-fns/locale/ro')).ro

    case Language.He.toLowerCase():
      return (await import('date-fns/locale/he')).he

    case Language.Sv.toLowerCase():
      return (await import('date-fns/locale/sv')).sv

    default:
      return (await import('date-fns/locale/en-GB')).enGB
  }
}
