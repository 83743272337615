import React from 'react'
import Avatar from '@mui/material/Avatar'

import { useMe } from './user.hook'

export interface UserAvatarProps {
  size?: 'default' | 'large'
}

export default function UserAvatar({ size }: UserAvatarProps) {
  const { firstName, lastName, fullName } = useMe()

  const userInitials = React.useMemo(() => {
    return `${(firstName || '').charAt(0)}${(lastName || '').charAt(0)}`
  }, [firstName, lastName])

  return (
    <Avatar
      alt={fullName || userInitials}
      sx={size === 'large'
        ? { width: 42, height: 42 }
        : { width: 32, height: 32, fontSize: 16 }
      }>
      {userInitials.toUpperCase()}
    </Avatar>
  )
}
