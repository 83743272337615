import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import fixingBugsSvg from '@straetus/react/assets/un-draw/fixing-bugs.svg'
import IllustrationContainer from '@straetus/react/components/illustration-container'

export interface ErrorBoundaryFallbackProps {
  withGoToDashboard?: boolean
}

export default function ErrorBoundaryFallback({ withGoToDashboard }: ErrorBoundaryFallbackProps) {
  const location = useLocation()

  const activeCompany = React.useMemo(() => {
    const [, activeCompany] = location.pathname.split('/')

    return activeCompany
  }, [location])

  const handleRefresh = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location.reload(true)
  }, [])

  const handleGoToDashboard = React.useCallback(() => {
    window.location.replace(`/${activeCompany}/dashboard`)
  }, [activeCompany])

  return (
    <IllustrationContainer
      illustration={fixingBugsSvg}
      title={(
        <FormattedMessage
          defaultMessage={'Oops!'}
          id={'error-boundary.title'} />
      )}>
      <Grid
        alignContent={'center'}
        direction={'column'}
        justifyContent={'center'}
        container>

        <Typography variant={'body1'}>
          <FormattedMessage
            defaultMessage={'We\'re sorry but there seems to be an temporary glitch in the system. Our administrators where already notified and we\'ll do our best to fix the problem as soon as possible.'}
            id={'error-boundary.explain'} />
        </Typography>

        <Grid
          gap={1}
          justifyContent={'flex-end'}
          sx={{ mt: 2 }}
          container>
          {withGoToDashboard && (
            <Button
              color={'info'}
              onClick={handleGoToDashboard}>
              <FormattedMessage
                defaultMessage={'Dashboard'}
                id={'btn.dashboard'} />
            </Button>
          )}

          <Button
            color={'primary'}
            onClick={handleRefresh}>
            <FormattedMessage
              defaultMessage={'Refresh page'}
              id={'btn.refresh-page'} />
          </Button>
        </Grid>
      </Grid>
    </IllustrationContainer>
  )
}
