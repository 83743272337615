import React, { ErrorInfo } from 'react'

import CloudErrorReporter from './error-boundary.api'
import { ErrorBoundaryContext } from './error-boundary.context'
import ErrorBoundaryFallback from './error-boundary-fallback.component'

export interface ErrorBoundaryProps {
  projectId: string
  apiKey: string
  service: string
  version: string
  withGoToDashboard?: boolean
}

export interface State {
  hasError: boolean
}

export const errorReporter = new CloudErrorReporter()

export class ErrorBoundary extends React.Component<React.PropsWithChildren<ErrorBoundaryProps>, State> {

  private readonly errorReporter: CloudErrorReporter

  public readonly state: State = {
    hasError: false
  }

  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.errorReporter = errorReporter
    this.errorReporter.start({
      key: props.apiKey,
      projectId: props.projectId,
      service: props.service,
      version: props.version,
      reportUnhandledPromiseRejections: false,
      disabled: !props.apiKey
    })
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('componentDidCatch', error, errorInfo)
    }

    // Message should not have the chunk load error
    this.errorReporter.report(error)
  }

  render() {
    const { children, withGoToDashboard } = this.props

    if (this.state.hasError) {
      return <ErrorBoundaryFallback withGoToDashboard={withGoToDashboard} />
    }

    return (
      <ErrorBoundaryContext.Provider value={this.errorReporter}>
        {children}
      </ErrorBoundaryContext.Provider>
    )
  }
}

export default ErrorBoundary
